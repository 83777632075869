export default {
	links: [
		{
			name: 'За дома',

			dropdownItems: [
				{
					name: 'Срочни',
					href: '/za-doma/',
					external: false
				},
				{
					name: 'Безсрочни',
					href: '/za-doma-bezsrochni/',
					external: false
				}
			]
		},
		{
			name: 'Свобода',
			href: '/svoboda/',
			external: false
		},
		{
			name: 'За бизнеса',
			dropdownItems: [
				{
					name: 'Срочни',
					href: '/za-biznesa/',
					external: false
				},
				{
					name: 'Безсрочни',
					href: '/za-biznesa-bezsrochni/',
					external: false
				}
			]
		},
		{
			name: 'Промоции',
			showPromoIcon: true,
			href: '/promocii/',
			external: false
		},
		{
			name: 'Aurora TV',
			href: '/aurora-tv/',
			external: false
		},
		{
			name: 'Закрилникът',
			href: '/internet-zashtita/',
			external: false
		},
		{
			name: 'За Теб',
			dropdownItems: [
				{
					name: 'Netsurf Play',
					href: '/netsurf-play/',
					external: false
				},
				{
					name: 'Какво е GPON',
					href: '/kakvo-e-gpon/',
					external: false
				},
				{
					name: 'Защо NetSurf',
					href: '/zashto-netsurf/',
					external: false
				},
				{
					name: 'Помощ',
					href: '/help-center/',
					external: false
				},
				{
					name: 'Блог',
					href: undefined,
					externalHref: 'https://blog.netsurf.bg/',
					external: true
				}
			]
		}
	]
};

