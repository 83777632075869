import React, { useCallback, useState } from 'react';

import { Link } from 'gatsby';
import constants from '../constants/footer';
import logoWhite from '../images/nav/logo-white.svg';

const Footer = () => {
	const [activeSection, setActiveSection] = useState();
	const toggle = useCallback(
		(e) => {
			if (e.currentTarget.classList.contains('no-after')) {
				e.currentTarget.classList.remove('no-after');
				setActiveSection(undefined);
			} else {
				e.currentTarget.classList.add('no-after');
				setActiveSection(e.currentTarget.id);
			}
		},
		[activeSection, setActiveSection]
	);

	return (
		<>
			<footer>
				{/* Top footer section */}
				<div className="row mx-2 footer-row px-2 px-lg-3 py-3">
					<div className="row d-flex mx-0 w-100 mt-2 px-lg-5 upper-half">
						<div className="col-md-6 col-xl-3 d-none d-md-block mb-md-5 mb-xl-0 pr-lg-5 order-md-0">
							<h3 className="mt-3 mb-2">Относно</h3>
							<img src={logoWhite} className="img-fluid mb-3" alt="Logo" />
							<p>
								NetSurf е водеща компания доставчик на интернет и
								телевизия с над 23,000 активни потребители в България.
							</p>
							<Link to="/zashto-netsurf/">Прочети още</Link>
						</div>

						<div className="d-none d-md-block col-12 col-lg-6 col-xl-3 px-lg-5 order-3">
							<div className="row">
								<div className="col-6 m-0 p-0">
									<h3 className="my-3">Услуги</h3>
									<div className="row p-0 m-0">
										<Link
											to="/za-doma/"
											className="d-block w-100 my-2"
										>
											За дома
										</Link>
										<Link
											to="/za-biznesa/"
											className="d-block w-100 my-2"
										>
											За офиса
										</Link>
										<Link
											to="/kakvo-e-gpon/"
											className="d-block w-100 my-2"
										>
											Какво е GPON
										</Link>
										<Link
											to="/netsurf-play/"
											className="d-block w-100 my-2"
										>
											NetSurf Play
										</Link>
										<Link
											to="/netsurf-assistant-app/"
											className="d-block w-100 my-2"
										>
											NetSurf Assistant
										</Link>
									</div>
								</div>
								<div className="col-6">
									<h3 className="my-3">Помощ</h3>
									<div className="row p-0 m-0">
										<a
											className="d-block w-100 my-2"
											href="https://forum.net-surf.net/"
											target="_blank"
											rel="noopener noreferrer"
										>
											Форум
										</a>
										<Link
											to="/magazini/"
											className="d-block w-100 my-2"
										>
											Магазини
										</Link>
										<Link
											to="/help-center/"
											className="d-block w-100 my-2"
										>
											Помощ
										</Link>
									</div>
								</div>
							</div>
						</div>
						<div className="d-none d-md-block col-12 col-lg-6 col-xl-3 px-lg-1 order-3">
							<h3 className="my-3 text-left">Още от NetSurf</h3>
							<div className="row p-0 m-0">
								<div className="col-6 m-0 p-0">
									<a
										href="https://netsurf.bg/wp-content/uploads/2019/12/Cookies_01_12_2019.pdf"
										target="_blank"
										rel="noopener noreferrer"
										className="d-block w-100 my-2"
									>
										Бисквитки
									</a>
									<Link
										to="/obshti-uslovia/"
										className="d-block w-100 my-2"
									>
										Общи условия и Бланки
									</Link>
									<Link
										to="/fondaciq-netsurf/"
										className="d-block w-100 my-2"
									>
										Фондация NetSurf
									</Link>
									<Link to="/documenti/" className="d-block w-100 my-2">
										ОПИК 2014-2020
									</Link>
								</div>
								<div className="col-6 m-0 p-0 text-right">
									{/* ### */}
									{/* <Link to='/ustroistva/' className='d-block w-100 my-2'>Устройства</Link> */}
									<Link to="/careers/" className="d-block w-100 my-2">
										Кариери
									</Link>
									<a
										href="https://blog.netsurf.bg/"
										className="d-block w-100 my-2"
									>
										Блог
									</a>
									<Link to="/magazini/" className="d-block w-100 my-2">
										Магазини
									</Link>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6 col-xl-3 px-xl-2 text-md-right text-center order-0 order-xl-3">
							<h3 className="mt-3 mb-2 text-green">Телефони</h3>
							<div className="d-inline">
								<div className="text-lg-right justify-content-between align-items-center">
									<div className="my-2">
										<p>Текущи клиенти:</p>
										<h2 className="">
											<a href={`tel:0885250000`}>0885250000</a>
										</h2>
									</div>
									<div className="my-2">
										<p>Нови клиенти:</p>
										<h2 className="">
											<a href={`tel:0882991611`}>0882991611</a>
										</h2>
									</div>
								</div>
							</div>
						</div>
						<div className="d-md-none">
							<div className="position-relative pt-10">
								<h3
									id="service"
									onClick={(e) => toggle(e)}
									className="expand my-3 title-wrapper"
								>
									Услуги
								</h3>
								{activeSection === 'service' && (
									<div className="pl-20">
										<div className="row p-0 m-0">
											<Link
												to="/za-doma/"
												className="d-block w-100 my-2"
											>
												За дома
											</Link>
											<Link
												to="/za-biznesa/"
												className="d-block w-100 my-2"
											>
												За офиса
											</Link>
											<Link
												to="/kakvo-e-gpon/"
												className="d-block w-100 my-2"
											>
												Какво е GPON
											</Link>
											<Link
												to="/netsurf-play/"
												className="d-block w-100 my-2"
											>
												NetSurf Play
											</Link>
											<Link
												to="/netsurf-assistant-app/"
												className="d-block w-100 my-2"
											>
												NetSurf Assistant
											</Link>
										</div>
									</div>
								)}
							</div>
							<div className="pt-10 position-relative">
								<h3
									id="help"
									onClick={(e) => toggle(e)}
									className="expand my-3 title-wrapper"
								>
									Помощ
								</h3>
								{activeSection === 'help' && (
									<div className="pl-20">
										<div className="row p-0 m-0">
											<a
												className="d-block w-100 my-2"
												href="https://forum.net-surf.net/"
												target="_blank"
												rel="noopener noreferrer"
											>
												Форум
											</a>
											<Link
												to="/magazini/"
												className="d-block w-100 my-2"
											>
												Магазини
											</Link>
											<Link
												to="/help-center/"
												className="d-block w-100 my-2"
											>
												Помощ
											</Link>
										</div>
									</div>
								)}
							</div>
							<div className="pt-10 position-relative">
								<h3
									id="more"
									onClick={(e) => toggle(e)}
									className="expand my-3 title-wrapper"
								>
									Още от Netsurf
								</h3>
								{activeSection === 'more' && (
									<div className="pl-20">
										<div className="row p-0 m-0">
											<div className="col-6 m-0 p-0">
												<a
													href="https://netsurf.bg/wp-content/uploads/2019/12/Cookies_01_12_2019.pdf"
													target="_blank"
													rel="noopener noreferrer"
													className="d-block w-100 my-2"
												>
													Бисквитки
												</a>
												<Link
													to="/obshti-uslovia/"
													className="d-block w-100 my-2"
												>
													Общи условия и Бланки
												</Link>
												<Link
													to="/fondaciq-netsurf/"
													className="d-block w-100 my-2"
												>
													Фондация NetSurf
												</Link>
												<Link
													to="/documenti/"
													className="d-block w-100 my-2"
												>
													ОПИК 2014-2020
												</Link>
											</div>
											<div className="col-6 m-0 p-0 text-right">
												{/* ### */}
												{/* <Link to='/ustroistva/' className='d-block w-100 my-2'>Устройства</Link> */}
												<Link
													to="/careers/"
													className="d-block w-100 my-2"
												>
													Кариери
												</Link>
												<a
													href="https://blog.netsurf.bg/"
													className="d-block w-100 my-2"
												>
													Блог
												</a>
												<Link
													to="/magazini/"
													className="d-block w-100 my-2"
												>
													Магазини
												</Link>
											</div>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>

					{/* Lower footer section */}
					<div className="row text-white d-flex justify-content-center w-100 mt-5 lower-half">
						<div className="col-12 col-md-5 text-center">
							<div className="d-md-inline-block">
								{constants.socialMediaIcons.map(
									({ link, icon_class }, index) => (
										<a
											className="m-2"
											key={index}
											href={link}
											target="_blank"
											rel="noopener noreferrer"
										>
											<span className={'icon ' + icon_class}></span>
										</a>
									)
								)}
							</div>
							<div className="mt-4 copyright">
								<p>Copyright © NetSurf, {new Date().getFullYear()}</p>
							</div>
						</div>
					</div>
				</div>
			</footer>
		</>
	);
};

export default Footer;

